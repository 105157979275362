/**************************************************
 * Nombre:       Soluciones
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {LINKPARTAFOLIO, NUMEROWASS, SERVICIOS} from "../../Constantes";
import {ArrowRight} from "iconsax-react";
import {theme} from "../../Tema";
import {iniciaChat} from "../../Utilidades/iniciaChat";
import {irUrl} from "../../Utilidades/irUrl";


const Soluciones = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (<Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: sCell ? 6 : 14}}
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <Typography sx={{fontSize: sCell ? 28 : 32, color: "#fff", fontWeight: 600}}>Soluciones para tus
                        sueños</Typography>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={4}
                    >

                        {SERVICIOS.map((it, index) => {
                            return (<Grid key={index} item lg={3} sm={6} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <Typography sx={{
                                                fontSize: 20, fontWeight: 600, color: "#fff"
                                            }}>{it.nombre}</Typography>
                                        </Grid>

                                        <Grid item lg={12} sm={12} xs={12}
                                              sx={{marginTop: it.nombre === 'Asesorías' && sCell ? 5.7 : 2}}>
                                            <Typography sx={{
                                                fontSize: 14, fontWeight: 400, color: "#fff"
                                            }}>{it.descripcion}</Typography>
                                        </Grid>

                                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                            <ButtonBase
                                                onClick={() => iniciaChat({
                                                    numero: NUMEROWASS,
                                                    mensaje: 'Hola Morris quiero conocer mas acerca de ' + it.nombre
                                                })}
                                            >
                                                <Typography sx={{fontSize: 14, fontWeight: 500, color: "#fff"}}>Conoce
                                                    más </Typography><span
                                                style={{marginBottom: -2, marginLeft: 5}}><ArrowRight color={'#fff'}
                                                                                                      size={"1rem"}/></span>
                                            </ButtonBase>
                                        </Grid>

                                    </Grid>
                                </Grid>)
                        })}

                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: masSM ? 16 : 12}}>
                    <Button
                        onClick={() => irUrl(LINKPARTAFOLIO)}
                        sx={{px: sCell ? 4 : 6, fontSize: sCell ? 18 : 20}}>
                        Descarga nuestro portafolio
                    </Button>
                </Grid>

            </Grid>

        </Grid>)
}

export default Soluciones;