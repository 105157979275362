/**************************************************
 * Nombre:       Tarjetas_Proyectos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {ButtonBase, Dialog, Grid, Typography, useMediaQuery} from "@mui/material";
import cuadrado from '../../Recursos/tmp_cudrado.svg'
import insta from '../../Recursos/ic_instagram.svg'
import face from '../../Recursos/ic_facebook.svg'
import titok from '../../Recursos/ic_tiktok.svg'
import {theme} from "../../Tema";
import DetalleProyecto from "../Componentes/SubComponentes/DetalleProyecto";
import {irUrl} from "../../Utilidades/irUrl";


const Tarjetas_Proyectos = ({valores}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [open, setOpen] = useState(false)

    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialog open={open} onClose={cerrar} fullWidth={true} maxWidth={'md'}>
                <DetalleProyecto valores={valores}/>
            </Dialog>


            <Grid item lg={12} sm={12} xs={12}>
                <ButtonBase
                    sx={{width: '100%'}}
                    onClick={() => abrir()}
                >
                    <img src={valores.img} width={'100%'} height={'auto'} style={{borderRadius: 14}}/>
                </ButtonBase>

            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Typography sx={{
                    fontSize: sCell ? 18 : 20,
                    fontWeight: 700,
                    color: '#fff',
                }}>{valores.nombre}</Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1, pr: 2}}>
                <Typography sx={{
                    fontSize: sCell ? 12 : 14,
                    fontWeight: 400,
                    color: '#fff',
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    textOverflow: "ellipsis",
                    textAlign: "left",
                }}>
                    {valores.descripcion}
                </Typography>
            </Grid>

            <Grid item lg={8} sm={12} xs={12} sx={{marginTop: sCell ? 1 : 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {valores.instagram &&
                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <ButtonBase
                                onClick={() => irUrl(valores.instagram)}
                                sx={{p: 1}}>
                                <img src={insta} width={20} height={'auto'}/>
                            </ButtonBase>
                        </Grid>
                    }

                    {valores.facebook &&
                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <ButtonBase
                                onClick={() => irUrl(valores.facebook)}
                                sx={{p: 1}}>
                                <img src={face} width={11} height={'auto'}/>
                            </ButtonBase>
                        </Grid>
                    }

                    {valores.tiktok &&
                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                            <ButtonBase
                                onClick={() => irUrl(valores.tiktok)}
                                sx={{p: 1}}>
                                <img src={titok} width={18} height={'auto'}/>
                            </ButtonBase>
                        </Grid>
                    }


                </Grid>
            </Grid>

        </Grid>
    )
}

export default Tarjetas_Proyectos;