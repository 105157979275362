/**************************************************
 * Nombre:       Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Potenciales from "../Tarjetas/Tarjeta_Potenciales";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {useState} from "react";
import {ACENTO} from "../../Constantes";

const Potenciales = () => {
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'potenciales',
        wheres: wheres,
        direccion: 'desc'
    })



    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{p: 3}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 28, fontWeight: 700}}>Potenciales</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Potenciales} data={data} spacing={2}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => cargarMas()}
                    variant={'outlined'}
                    sx={{color: ACENTO}}
                >
                    Cargar Mas
                </Button>
            </Grid>


        </Grid>
    )

}
export default Potenciales