// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// somosmorris.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyAhhgAc7ovo237dCCF2UtpMW4x3lAz79f8",
    authDomain: "somosmorris-preview.firebaseapp.com",
    projectId: "somosmorris-preview",
    storageBucket: "somosmorris-preview.appspot.com",
    messagingSenderId: "433604965010",
    appId: "1:433604965010:web:a47c8c031be540f5956c4b",
    measurementId: "G-5DDDTVLTYG"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)