/**************************************************
 * Nombre:       ParteNosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../Tema";
import {ACENTO} from "../../Constantes";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Parte_Nosotros from "../Formularios/Formulario_Parte_Nosotros";
import {LoadingButton} from "@mui/lab";
import {ArrowRight} from "iconsax-react";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";


const ParteNosotros = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Parte_Nosotros,
        nombre: 'nosotros'
    })
    const [cargando, setCargando] = useState(false)


    const guardar = () => {

        if (entidad.nombre === undefined || entidad.correo === undefined) {
            alert('Nombre y correo es requerido')
            return
        }

        let obj = entidad
        obj.fecha = new Date().getTime()
        setCargando(true)
        guardarDoc('aplicantes', obj).then((dox) => {
            if (dox.res) {
                alert('Datos enviados con exito\nMuy pronto nos contactaremos contigo')
                limpiarEntidad()
            } else {
                alert('Algo inesperado acaba de pasar, por favor intentalo nuevamente')
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: 'rgba(255, 255, 255, 0.10)'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: masSM ? 14 : 6, marginBottom: 8}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography sx={{color: ACENTO, fontSize: sCell ? 24 : 28, fontWeight: 700, textAlign: 'center'}}>¿Quieres
                        ser
                        parte de nuestro equipo
                        de trabajo?</Typography>
                </Grid>

                <Grid item lg={8} sm={12} xs={12} sx={{marginTop: 4}}>
                    <Typography sx={{color: '#fff', fontSize: 14, fontWeight: 300, textAlign: 'center'}}>
                        Creemos firmemente que las personas son el motor del mundo, y es por eso que contamos con
                        profesionales con una visión orientada al futuro, con el objetivo de generar un impacto positivo
                        en los negocios de nuestros clientes, adaptarse y transformar su entorno.
                    </Typography>
                </Grid>

                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <Formulario {...props} />
                </Grid>

                <Grid item lg={8} sm={12} xs={12} sx={{marginTop: 4}}>
                    <LoadingButton
                        onClick={() => guardar()}
                        loading={cargando}
                        variant={'contained'} endIcon={<ArrowRight color={'#fff'} size={'1.2rem'}/>}>
                        Enviar
                    </LoadingButton>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default ParteNosotros;