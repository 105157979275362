/**************************************************
 * Nombre:       Testimonios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import comillas from '../../Recursos/comillas.svg'
import {Splide, SplideSlide} from "@splidejs/react-splide";
import Tarjetas_Testimonios from "../Tarjetas/Tarjetas_Testimonios";
import {theme} from "../../Tema";


const Testimonios = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={comillas} width={50} height={'auto'}/>
                </Grid>

                <Grid item lg={8} sm={8} xs={11}>
                    <Splide aria-label="My Favorite Images" style={{width: "100%"}}
                            options={{
                                autoplay: true,
                                speed: 30000,
                                rewind: true,
                                perPage: 1,
                                arrows: false,
                                gap: 16,
                                pagination: false

                            }}
                    >
                        {items.map((item, index) => {
                            return (
                                <SplideSlide key={index}>
                                    <Tarjetas_Testimonios valores={item}/>
                                </SplideSlide>
                            )
                        })}


                    </Splide>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Testimonios;

const items = [
    'Nuestra marca experimentó una transformación digital con resultados sorprendentes gracias a la visión única de Morris. Con su creatividad en redes sociales, conectamos con una audiencia más amplia, llevando nuestra presencia en línea a nuevos horizontes. Su comprensión profunda de la esencia digital es evidente en cada paso.',
    'La gestión de redes sociales va más allá con Morris; crean una comunidad activa alrededor de nuestra marca. Observamos un aumento significativo en interacciones, comentarios y compartidos. Convertir nuestras redes sociales en un lugar vibrante es solo una muestra del impacto que esta agencia tiene en nuestra presencia digital.',
    'El diseño del logotipo fue solo el comienzo; Morris construyó una experiencia de marca integral. Desde las redes sociales hasta nuestro sitio web, cada elemento refleja coherencia y profesionalismo. Gracias a ellos, confiamos en que nuestra marca está representada de manera sólida en todos los aspectos.',
    'Más allá de mejorar la estética, esta agencia desarrolló una estrategia de contenido impactante. Cada publicación en redes sociales cuenta una historia que resuena con nuestra audiencia, generando un impacto significativo. Su calidad y creatividad han elevado nuestro contenido a nuevos niveles.',
    'Obtener una ventaja competitiva en un mercado saturado fue posible gracias a la habilidad de los chicos Morris para destacar nuestra marca. La identidad y presencia que hemos logrado son incomparables, dejando a la competencia rezagada.',
    'La Agencia Morris dio vida a nuestra marca de formas inimaginables. Su enfoque creativo y estratégico nos destacó en un mercado saturado. La nueva identidad y coherencia en todos nuestros canales son testimonio de su impacto duradero.',
    'Desde que Morris se hizo cargo de nuestras redes sociales, hemos experimentado un crecimiento impresionante en nuestra presencia en línea. Su capacidad para crear contenido atractivo y gestionar comunidades nos ha permitido conectar de manera más efectiva. Los resultados hablan por sí mismos.',
    'La decisión de contratar una agencia como Morris  para el branding y manejo de redes sociales fue transformadora. No solo nos proporcionaron una identidad visual sólida, sino que también revitalizaron nuestras plataformas sociales. La atención al detalle y la creatividad son insuperables, destacando nuestra marca y aumentando nuestras interacciones en redes sociales.',
    'Más que diseñar un logo y un conjunto de colores, Agencia Morris creó una historia visual que resuena con nuestra audiencia. Su enfoque estratégico en el branding nos diferenció en el mercado, proporcionando una base sólida para todas nuestras actividades de marketing',

]