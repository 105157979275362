/**************************************************
 * Nombre:       Formulario_Proyecto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import {Facebook, Flag, GalleryTick, Instagram, Link2, Message, Text} from "iconsax-react";
import {CATEGORIAS} from "../../Constantes";

const Formulario_Proyecto = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>

                </Grid>

                <Grid item container lg={8} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.ImagenDimencion dato={'img'} carpeta={'portafolio'} x={1} y={1}
                                              funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.DividerTexto texto={'Datos del proyecto'}/>
                </Grid>

                <Grid item container lg={7} sm={7} xs={7} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIconSelect Icono={Flag} label={'Categoria'} dato={'categoria'}
                                              opciones={CATEGORIAS}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon Icono={GalleryTick} label={'Nombre del proyecto'} dato={'nombre'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon Icono={Text} label={'Subtitulo del proyecto'} dato={'subtitulo'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon Icono={Message} label={'Descripcion del Proyecto'} dato={'descripcion'}
                                        lineas={5}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.DividerTexto texto={'Redes'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon Icono={Instagram} label={'Url Instagram'} dato={'instagram'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon Icono={Facebook} label={'Url Facebook'} dato={'facebook'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon Icono={Link2} label={'Url Tiktok'} dato={'tiktok'}/>
                </Grid>

            </Grid>
        </FormBase>
    )

}
export default Formulario_Proyecto