/**************************************************
 * Nombre:       Formulario_Contactanos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Flag, Message, Sms, User, Whatsapp} from "iconsax-react";


const Formulario_Contactanos = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Nombre completo'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Sms} label={'Correo'} dato={'correo'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Whatsapp} label={'Telefono'} dato={'telefono'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Flag} label={'Asunto'} dato={'asunto'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Message} label={'Mensaje'} dato={'mensaje'}/>
                </Grid>

            </Grid>
        </FormBase>
    )
}

export default Formulario_Contactanos;