/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import logo from '../../Recursos/logo.svg'
import {theme} from "../../Tema";
import MenuPc from "./Componentes/MenuPc";


const Barra = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: masSM ? 20 : 4, py: 2, marginTop: 4}}
            >

                <Grid item lg={3} sm={3} xs={0}>

                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: sCell ? 'flex-start' : "center"}}>
                    <img src={logo} width={masSM ? 120 : 110} height={'auto'}/>
                </Grid>

                <Grid item container lg={3} sm={3} xs={6} sx={{justifyContent: "flex-end"}}>

                    <MenuPc/>

                </Grid>


            </Grid>

        </Grid>
    )
}

export default Barra;