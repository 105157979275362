/**************************************************
 * Nombre:       Tarjeta_Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Tooltip} from "@mui/material";
import {CardBase, Imagen} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Flag, GalleryTick, Message, Mobile, Sms, Text, Trash, User} from "iconsax-react";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";

const Tarjeta_Proyectos = ({valores, click}) => {

    return (
        <ButtonBase onClick={() => click(valores)} sx={{textAlign: 'left', width: '100%'}}>
            <CardBase valores={valores}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    sx={{p: 1}}
                >

                    <Grid item container lg={1} sm={2} xs={2} sx={{justifyContent: 'flex-start'}}>
                        <CardBase.Imagen dato={'img'}/>
                    </Grid>

                    <Grid item container lg={4} sm={8} xs={8} sx={{justifyContent: 'flex-start'}}>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <CardBase.IconTexto dato1={'nombre'} Icono={GalleryTick}/>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <CardBase.IconTexto dato1={'subtitulo'} Icono={Text}/>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <CardBase.IconTexto dato1={'categoria'} Icono={Flag}/>
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <CardBase.IconTextoParrafo dato1={'descripcion'} lineas={4}/>
                    </Grid>


                </Grid>
            </CardBase>
        </ButtonBase>
    )

}
export default Tarjeta_Proyectos