/**************************************************
 * Nombre:       DetalleProyecto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import coete from '../../../Recursos/coete.png'
import cuadrado from '../../../Recursos/tmp_cudrado.svg'
import {ACENTO, REDES} from "../../../Constantes";
import BotonesAccion from "./BotonesAccion";
import {theme} from "../../../Tema";
import {ArrowLeft, ArrowLeft2, CloseSquare} from "iconsax-react";
import {irUrl} from "../../../Utilidades/irUrl";
import insta from '../../../Recursos/ic_instagram.svg'
import face from '../../../Recursos/ic_facebook.svg'
import tikt from '../../../Recursos/ic_tiktok.svg'


const DetalleProyecto = ({valores}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                <img src={coete} width={250} height={'auto'}/>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{p: 4, marginTop: -21}}
                spacing={4}
            >

                {!masSM &&
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-start",}}>
                        <Button startIcon={<ArrowLeft size={'1.3rem'}/>}
                                sx={{px: 2, fontSize: 14}}
                        >Atras</Button>
                    </Grid>
                }

                <Grid item lg={5} sm={5} xs={12}>
                    <img src={valores.img} width={'100%'} height={'auto'} style={{borderRadius: 14}}/>
                </Grid>

                <Grid item lg={7} sm={7} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                            <Typography sx={{color: ACENTO, fontSize: 22, fontWeight: 800}}>
                                {valores.nombre}
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 0}}>
                            <Typography sx={{color: '#fff', fontSize: 16, fontWeight: 400}}>
                                {valores.subtitulo}
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}
                              sx={{borderTop: 1, borderColor: ACENTO, width: '60%', mx: 1, marginTop: 4}}/>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <Typography sx={{color: '#fff', fontSize: 12, fontWeight: 400}}>
                                {valores.descripcion}
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 3 : 4}}>
                            <BotonesAccion difiere={true}/>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 3}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >

                                {valores.instagram &&
                                    <Grid item container lg={1} sm={1} xs={2}
                                          sx={{justifyContent: "center"}}>
                                        <ButtonBase
                                            onClick={() => irUrl(valores.instagram)}
                                            sx={{p: 1}}>
                                            <img src={insta} width={21} height={'auto'}/>
                                        </ButtonBase>
                                    </Grid>
                                }

                                {valores.facebook &&
                                    <Grid item container lg={1} sm={1} xs={2}
                                          sx={{justifyContent: "center"}}>
                                        <ButtonBase
                                            onClick={() => irUrl(valores.facebook)}
                                            sx={{p: 1}}>
                                            <img src={face} width={12} height={'auto'}/>
                                        </ButtonBase>
                                    </Grid>
                                }

                                {valores.tiktok &&
                                    <Grid item container lg={1} sm={1} xs={2}
                                          sx={{justifyContent: "center"}}>
                                        <ButtonBase
                                            onClick={() => irUrl(valores.tiktok)}
                                            sx={{p: 1}}>
                                            <img src={tikt} width={20} height={'auto'}/>
                                        </ButtonBase>
                                    </Grid>
                                }


                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default DetalleProyecto;