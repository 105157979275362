/**************************************************
 * Nombre:       MenuPc
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";


const MenuPc = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
        >

            <Grid item container lg={4} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                <ButtonBase href={'#contactos'}>
                    <Typography sx={{color: '#fff', fontSize: sCell ? 14 : 16, fontWeight: 500}}> Contactos</Typography>
                </ButtonBase>
            </Grid>

            <Grid item container lg={4} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                <ButtonBase href={'#portafolio'}>
                    <Typography
                        sx={{color: '#fff', fontSize: sCell ? 14 : 16, fontWeight: 500}}> Portafolio</Typography>
                </ButtonBase>
            </Grid>

        </Grid>
    )
}

export default MenuPc;