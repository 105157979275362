/**************************************************
 * Nombre:       Dialogo_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Login from "../Formularios/Formulario_Login";
import logo from '../../Recursos/logo.svg'
import {LoadingButton} from "@mui/lab";
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";


const Dialogo_Login = () => {
    const {Formulario, props, entidad} = useFormulario({Form: Formulario_Login, nombre: 'login'})
    const [cargando, setCargando] = useState(false)

    const ingresar = () => {
        setCargando(true)
        funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
            if (dox.res) {

            } else {
                alert(dox.data)
            }

            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: 2}}
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <img src={logo} width={150} height={'auto'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <LoadingButton
                    variant={'contained'}
                    loading={cargando}
                    onClick={() => ingresar()}
                >
                    Ingresar
                </LoadingButton>
            </Grid>

            git

        </Grid>
    )
}

export default Dialogo_Login;