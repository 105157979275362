/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Dialog, Grid, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery} from "@mui/material";
import {ACENTO} from "../../Constantes";
import {styled} from "@mui/material/styles";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import Tarjetas_Proyectos from "../Tarjetas/Tarjetas_Proyectos";
import {theme} from "../../Tema";
import DetalleProyecto from "./SubComponentes/DetalleProyecto";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {where} from "firebase/firestore";


const Catalogo = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const sTab = useMediaQuery(theme.breakpoints.only('sm'))
    const [escogido, setEscogido] = useState('Branding');
    const [wheres, setWheres] = useState([where('categoria', '==', escogido)])
    const {data} = useRecuperarColleccionListeningProg({
        col: 'proyectos',
        orden: 'nombre',
        direccion: 'desc',
        wheres: wheres,
        numero: 20
    })

    const cambioCategoria = (e) => {
        setEscogido(e)
        setWheres([where('categoria', '==', e)])
    }

    const cambio = (event, nuevoValor) => {
        console.log(nuevoValor)
        setEscogido(nuevoValor);
    };


    const Toggle = styled(ToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: ACENTO
        }
    });

    const getX = () => {


        if (sCell) {
            return 2.5
        }

        if (sTab) {
            return 5
        }

        return 8;
    }

    const getNumProyectos = () => {


        if (sCell) {
            return 2
        }

        if (sTab) {
            return 4
        }

        return 5;

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <ToggleButtonGroup
                            value={escogido}
                            exclusive
                            onChange={cambio}
                            aria-label="text alignment"
                            color={'secondary'}
                            size={'small'}
                        >
                            <Toggle
                                onClick={() => cambioCategoria('Branding')}
                                value="Branding"
                                sx={{
                                    px: getX(),
                                    borderRadius: 4,
                                    borderColor: '#fff',
                                }}>
                                <Typography
                                    sx={{color: "#fff", textTransform: 'none', fontWeight: 600}}>Branding</Typography>
                            </Toggle>
                            <Toggle
                                onClick={() => cambioCategoria('Social Media')}
                                value="Social Media"
                                sx={{
                                    px: masSM ? 6 : 3,
                                    borderColor: '#fff',
                                }}>
                                <Typography sx={{color: "#fff", textTransform: 'none', fontWeight: 600}}>Social
                                    Media</Typography>
                            </Toggle>
                            <Toggle
                                onClick={() => cambioCategoria('Desarrollo Web')}
                                value="Desarrollo Web"
                                sx={{
                                    px: masSM ? 5 : 2.5,
                                    borderRadius: 4,
                                    borderColor: '#fff',
                                }}>
                                <Typography sx={{color: "#fff", textTransform: 'none', fontWeight: 600}}>Desarrollo
                                    Web</Typography>
                            </Toggle>
                        </ToggleButtonGroup>

                    </Grid>
                </Grid>


            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 12, px: masSM ? 0 : 4}}>
                <Splide aria-label="My Favorite Images" style={{width: "100%"}}
                        options={{
                            autoplay: false,
                            speed: 5000,
                            rewind: true,
                            perPage: getNumProyectos(),
                            arrows: masSM ? true : false,
                            gap: 16,
                            pagination: false

                        }}
                >
                    {data.map((item, index) => {
                        return (
                            <SplideSlide key={index}>
                                <Tarjetas_Proyectos valores={item}/>
                            </SplideSlide>
                        )
                    })}


                </Splide>
            </Grid>

        </Grid>
    )
}

export default Catalogo;

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]