/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import {COLORFONDO} from "../Constantes";
import Barra from "./Barra/Barra";
import Portada from "./Componentes/Portada";
import ceraparador from '../Recursos/ceparador.svg'
import Indicadores from "./Componentes/Indicadores";
import Soluciones from "./Componentes/Soluciones";
import Catalogo from "./Componentes/Catalogo";
import Testimonios from "./Componentes/Testimonios";
import Ubicacion from "./Componentes/Ubicacion";
import Footer from "./Footer";
import {theme} from "../Tema";
import ParteNosotros from "./Componentes/ParteNosotros";


const Pagina = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: COLORFONDO}}
        >


            <Grid item lg={12} sm={12} xs={12}>
                <Barra/>
            </Grid>

            <Grid
                component={'div'}
                id={'inicio'}
                item lg={12} sm={12} xs={12}>

            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 12 : 20}}>
                <Portada/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 14 : 20}}>
                <Indicadores/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 14}}>
                <img src={ceraparador} width={'100%'} height={'auto'}/>
            </Grid>

            <Grid
                component={'div'}
                id={'servicios'}
                item lg={12} sm={12} xs={12}>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 20 : 13}}>
                <Soluciones/>
            </Grid>

            <Grid
                component={'div'}
                id={'portafolio'}
                item lg={12} sm={12} xs={12}>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 18 : 11}}>
                <Catalogo/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 24 : 14}}>
                <Testimonios/>
            </Grid>

            <Grid
                component={'div'}
                id={'contactos'}
                item lg={12} sm={12} xs={12}>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 20 : 14}}>
                <Ubicacion/>
            </Grid>


            <Grid
                item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? 20 : 14}}>
                <ParteNosotros/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 20}}>
                <Footer/>
            </Grid>

        </Grid>
    )
}

export default Pagina;