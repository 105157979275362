/**************************************************
 * Nombre:       Ubicacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Contactanos from "../Formularios/Formulario_Contactanos";
import {LoadingButton} from "@mui/lab";
import {ArrowRight} from "iconsax-react";
import {theme} from "../../Tema";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";


const Ubicacion = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({
        Form: Formulario_Contactanos,
        nombre: 'contactanos'
    })
    const [cargando, setCargando] = useState(false)


    const guardar = () => {

        if (entidad.nombre === undefined || entidad.correo === undefined){
            alert('Nombre y correo es requerido')
            return
        }

        let obj = entidad
        obj.fecha = new Date().getTime()
        setCargando(true)
        guardarDoc('potenciales', obj).then((dox) => {
            if (dox.res) {
                alert('Datos enviados con exito\nMuy pronto nos contactaremos contigo')
                limpiarEntidad()
            } else {
                alert('Algo inesperado acaba de pasar, por favor intentalo nuevamente')
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: masSM ? 14 : 6}}
                spacing={10}
            >


                <Grid item lg={6} sm={6} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#fff", fontSize: 24, fontWeight: 600}}>
                                Nuestra ubicación
                            </Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 400}}>
                                Construyamos juntos un universo creativo para tu marca
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 3}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 400}}>
                                <span style={{fontWeight: 600, marginRight: 5}}> Sede Pasto: </span>
                                Calle 19 #31b-57 Edificio Novacentro Oficina 803
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 400}}>
                                <span style={{fontWeight: 600, marginRight: 5}}>Sede Ipiales: </span>
                                Calle 14 #7-15 3er piso
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 400}}>
                                <span style={{fontWeight: 600, marginRight: 5}}>Celular:  </span>
                                +57 316 495 3842
                            </Typography>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 3}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 600}}>
                                Lunes- Viernes
                            </Typography>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: masSM ? 3 : 1.5}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 400}}>
                                8:00 A.M. - 6:00 P.M.
                            </Typography>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 600}}>
                                Sábado
                            </Typography>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 400}}>
                                8:00 A.M. - 1:00 P.M.
                            </Typography>
                        </Grid>

                        <Grid item lg={3} sm={4} xs={4} sx={{borderRight: 1, borderColor: '#fff', marginTop: 7}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 600,}}>
                                +57 316 256 7312
                            </Typography>
                        </Grid>

                        <Grid item lg={8} sm={6} xs={6} sx={{px: masSM ? 2 : 1}}>
                            <Typography sx={{color: "#fff", fontSize: 14, fontWeight: 600, marginTop: 7}}>
                                info@somosmorris.com
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: '#fff', fontWeight: 600, fontSize: 24}}>
                                Contáctanos
                            </Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <Formulario {...props} />
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                            <LoadingButton
                                onClick={() => guardar()}
                                variant={'contained'} endIcon={<ArrowRight color={'#fff'} size={'1.2rem'}/>}>
                                Enviar
                            </LoadingButton>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Ubicacion;