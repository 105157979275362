/**************************************************
 * Nombre:       Formulario_Contactanos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Award, Flag, Message, Sms, User, Whatsapp} from "iconsax-react";


const Formulario_Parte_Nosotros = ({...props}) => {

    return (
        <FormBase {...props} >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={User} label={'Nombre completo'} dato={'nombre'}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={12}>
                    <FormBase.InputIcon Icono={Award} label={'Cargo deseado'} dato={'cargo'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon Icono={Sms} label={'Correo Electronico'} dato={'correo'}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Parte_Nosotros;