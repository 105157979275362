/**************************************************
 * Nombre:       Dialogo_Proyecto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import Formulario_Proyecto from "../Formularios/Formulario_Proyecto";
import {LoadingButton} from "@mui/lab";
import {useEffect, useState} from "react";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";

const Dialogo_Proyecto = ({id, click}) => {
    const {Formulario, props, entidad, limpiarEntidad, setEntidad} = useFormulario({
        Form: Formulario_Proyecto,
        nombre: 'proyecto'
    })
    const [cargando, setCargando] = useState(false)

    const guardar = () => {
        let obj = entidad
        setCargando(true)
        guardarDoc('proyectos', obj).then((dox) => {
            if (dox.res) {
                alert('Proyecto guardado con exito')
                limpiarEntidad()
            } else {
                alert('Algo inesperado acaba de pasar, por favor intentalo nuevamente')
            }
            setCargando(false)
        })
    }

    const borrar = () => {
        borrarDoc('proyectos', entidad.id).then((dox) => {
            if (dox.res) {
                alert('Proyecto borrado')
            }
        })
    }


    useEffect(() => {
        if (id && id.id) {
            obtenerDoc('proyectos', id.id).then((dox) => {
                console.log(dox)
                if (dox.res) {
                    setEntidad(dox.data)
                } else {
                    setEntidad({})
                }
            })
        }


    }, [id]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Formulario {...props} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', mt: 4, mb: 2}}>
                <LoadingButton
                    onClick={() => guardar()}
                    loading={cargando}
                    variant={'contained'}
                >
                    GUARDAR CAMBIOS
                </LoadingButton>
            </Grid>

            {id && id.id &&
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Button
                        sx={{px: 0}}
                        onClick={() => borrar()}
                        variant={'text'}>
                        Borrar Proyecto
                    </Button>
                </Grid>
            }

        </Grid>
    )

}
export default Dialogo_Proyecto