/**************************************************
 * Nombre:       Portada
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import luna from '../../Recursos/luna.png'
import {REDES} from "../../Constantes";
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";
import BotonesAccion from "./SubComponentes/BotonesAccion";
import {marcarEvento} from "../../Servicios/Analiticas/marcarEvento";


const Portada = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{maxWidth: '1400px', px: masSM ? 20 : 4}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{color: '#fff', fontSize: masSM ? 50 : 32, fontWeight: 400, textAlign: 'center', fontFamily: 'Space Grotesk'}}>

                    SOMOS TU
                        <span style={{fontWeight: 600, paddingLeft: 12}} >UNIVERSO CREATIVO</span>

                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 2}}>
                    <img src={luna} width={masSM ? 320 : 320} height={'auto'}/>
                </Grid>

                <Grid item lg={7} sm={9} xs={12} sx={{marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={8} sm={12} xs={8} sx={{justifyContent: "flex-end"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >

                                {REDES.map((it, index) => {
                                    return (
                                        <Grid item key={index} container lg={1} sm={1} xs={2}
                                              sx={{justifyContent: "center"}}>
                                            <ButtonBase
                                                onClick={() => {
                                                    marcarEvento('boton_'+it.nombre)
                                                    irUrl(it.url)
                                                }}
                                                sx={{p: 1}}>
                                                <img src={it.img} width={it.size} height={'auto'}/>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={7} sm={9} xs={12} sx={{marginTop: 1, px: masSM ? 0 : 1}}>
                    <Typography sx={{fontSize: 14, color: '#fff', fontWeight: 400, textAlign: 'justify'}}>Creemos
                        firmemente que las personas son el motor del mundo, y es por eso que contamos con profesionales
                        con una visión orientada al futuro, con el objetivo de generar un impacto positivo en los
                        negocios de nuestros clientes, adaptarse y transformar su entorno.
                        En Morris construimos marcas con propósitos sólidos a través de objetivos cuantificables que
                        permitan un crecimiento sostenible.
                    </Typography>
                </Grid>


                <Grid item lg={7} sm={9} xs={12} sx={{marginTop: 8}}>
                    <BotonesAccion/>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Portada;