/**************************************************
 * Nombre:       Indicadores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {INDICADORES} from "../../Constantes";
import {theme} from "../../Tema";
import {CountUp, useCountUp} from 'use-count-up'
import {useInView} from "react-intersection-observer";



const Indicadores = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [ref, inView] = useInView();

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', pl: sCell ? 6 : 14, pr: 0}}
                spacing={masSM ? 0 : 4}
                ref={ref}

            >

                {INDICADORES.map((it, index) => {
                    return (
                        <Grid key={index} item lg={3} sm={3} xs={6}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                sx={{borderLeft: 5, borderColor: '#fff', px: 2}}
                            >

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{
                                        fontSize: masSM ? 24 : 20,
                                        fontWeight: 700,
                                        color: '#fff'
                                    }}>{'+ '}
                                        <CountUp
                                            isCounting={inView}
                                            end={it.numero}
                                            duration={5}
                                            thousandsSeparator={"."}
                                            start={0}
                                            easing={"easeInCubic"}

                                        /></Typography>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: masSM ? -0.7 : 0}}>
                                    <Typography sx={{
                                        fontSize: masSM ? 16 : 12,
                                        fontWeight: 400,
                                        color: '#fff',
                                        lineHeight: 1.1
                                    }}>{it.nombre}

                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    )
                })}


            </Grid>

        </Grid>
    )
}

export default Indicadores;