import insta from './Recursos/ic_instagram.svg'
import face from './Recursos/ic_facebook.svg'
import tiktok from './Recursos/ic_tiktok.svg'

export const COLORFONDO = '#00000F'

export const ACENTO = '#DF4149'

export const NUMEROWASS = '3164953842'

export const LINKWASS = 'https://api.whatsapp.com/send?phone=573164953842&text=Hola%20quiero%20chatear%20con%20Morris'

export const LINKPARTAFOLIO = 'https://luismrosero.com/portafolio/portafolio.pdf'

export const LINKCALENDY = 'https://calendly.com/agenciamorris/asesoria-1-a-1'

export const CATEGORIAS = ['Branding', 'Social Media', 'Desarrollo Web']

export const REDES = [{
    nombre: 'instagram', img: insta, url: 'https://www.instagram.com/somosmorris/', size: 21
}, {
    nombre: 'facebook', img: face, url: 'https://www.facebook.com/somosmorris', size: 12
}, {
    nombre: 'tiktok', img: tiktok, url: 'https://www.tiktok.com/@somosmorris', size: 20
}]

export const INDICADORES = [{
    numero: 150, nombre: 'Marcas construidas'
}, {
    numero: 2000, nombre: 'Clientes Potenciales'
}, {
    numero: 27000, nombre: 'Me gusta'
}, {
    numero: 7, nombre: 'Años de Experiencia'
},]

export const SERVICIOS = [{
    nombre: 'Branding',
    descripcion: 'Identidades unicas y estratégica con Morris. Nos enfocamos en conectar emocionalmente con el público y construir una imagen confiable y atractiva.'
}, {
    nombre: 'Social media',
    descripcion: 'Maximiza tu presencia en redes con Morris, construimos una presencia dinámica y atractiva que conecta con tu audiencia en todas las plataformas.'
}, {
    nombre: 'Desarrollo Web',
    descripcion: 'Crea una  página web  visualmente atractiva, intuitiva y efectiva, en Morris nos  especializamos  en crear experiencias web excepcionales y funcionales. '
}, {
    nombre: 'Asesorías',
    descripcion: 'Te ayudamos a crear tu modelo de negocio, desarrollar estrategías de mercadeo y gestionar tus redes sociales, con Morris, tu visión se convierte en realidad. '
},]