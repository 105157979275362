/**************************************************
 * Nombre:       Tarjeta_Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Tooltip} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Flag, Mobile, Sms, Trash, User} from "iconsax-react";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";

const Tarjeta_Potenciales = ({valores, click}) => {

    const borrar = () => {
        borrarDoc('potenciales', valores.id).then((dox) => {
            if (dox.res) {
                alert('Potencial borrado con exito')
            }
        })
    }

    return (<CardBase valores={valores}>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{p: 1}}
        >

            <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                <CardBase.FechaComponente dato1={'fecha'}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <CardBase.IconTexto dato1={'nombre'} Icono={User}/>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <CardBase.IconTexto dato1={'correo'} Icono={Sms}/>
                        </Grid>

                    </Grid>

                </CardBase.FechaComponente>
            </Grid>

            <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                >
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <CardBase.IconTexto dato1={'telefono'} Icono={Mobile}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <CardBase.IconTexto dato1={'asunto'} Icono={Flag}/>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                <CardBase.IconTextoParrafo dato1={'mensaje'} Icono={User}/>
            </Grid>

            <Grid item container lg={1} sm={1} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Tooltip title={'Borrar Potencial'}>
                    <ButtonBase
                        onClick={() => borrar()}
                        sx={{borderRadius: 2, p: 0.4}}>
                        <Trash variant={'Bold'} size={'1.8rem'}/>
                    </ButtonBase>
                </Tooltip>
            </Grid>


        </Grid>
    </CardBase>)

}
export default Tarjeta_Potenciales