/**************************************************
 * Nombre:       BotonesAccion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Tooltip, useMediaQuery} from "@mui/material";
import {Message, UserSquare, Video, Whatsapp} from "iconsax-react";
import {theme} from "../../../Tema";
import {irUrl} from "../../../Utilidades/irUrl";
import {LINKCALENDY, LINKWASS} from "../../../Constantes";
import {marcarEvento} from "../../../Servicios/Analiticas/marcarEvento";


const BotonesAccion = ({difiere = false}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: sCell ? 'center' : "flex-start"}}>
                <Tooltip title={'Inicia un chat WhatsApp'}>
                    <Button
                        onClick={() => {
                            marcarEvento('iniciar_chat')
                            irUrl(LINKWASS)
                        }}
                        sx={{fontSize: difiere ? 16 : 20, width: difiere ? 200 : 250}}
                        startIcon={<Whatsapp variant={'Bold'}/>}
                    >Chatea</Button>
                </Tooltip>
            </Grid>

            <Grid item container lg={6} sm={6} xs={12}
                  sx={{justifyContent: sCell ? 'center' : "flex-end", marginTop: sCell ? 4 : 0}}>
                <Tooltip title={'Agenda un reunion 1 a 1 con un especialista'}>
                    <Button
                        onClick={() => {
                            marcarEvento('Iniciar_calendy')
                            irUrl(LINKCALENDY)
                        }}
                        sx={{fontSize: difiere ? 16 : 20, width: difiere ? 200 : 250}}
                        startIcon={<Video variant={'Bold'}/>}
                    >Asesoria 1:1</Button>
                </Tooltip>
            </Grid>


        </Grid>
    )
}

export default BotonesAccion;