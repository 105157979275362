/**************************************************
 * Nombre:       Tarjetas_Proyectos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import cuadrado from '../../Recursos/tmp_cudrado.svg'
import insta from '../../Recursos/ic_instagram.svg'
import face from '../../Recursos/ic_facebook.svg'
import titok from '../../Recursos/ic_tiktok.svg'
import {theme} from "../../Tema";


const Tarjetas_Testimonios = ({valores}) => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item lg={11} sm={12} xs={12} sx={{marginTop: 2}}>
                <Typography sx={{fontSize: masSM ? 20 : 14, fontWeight: 500, color: '#fff',textAlign: 'center'}}>
                    {valores}
                </Typography>
            </Grid>


        </Grid>
    )
}

export default Tarjetas_Testimonios;