/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from '../Recursos/logo.svg'
import {REDES} from "../Constantes";
import {theme} from "../Tema";
import {irUrl} from "../Utilidades/irUrl";
import {useDialogo} from "../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Login from "../Login/Dialogos/Dialogo_Login";


const Footer = () => {
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Login})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: masSM ? 14 : 4, mb: 6}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <img src={logo} width={130} height={'auto'}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{borderBottom: 1, borderColor: '#fff', p: 1}}
                    >

                        <Grid item container lg={11} sm={10} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent={"space-between"}
                                alignItems="flex-start"
                            >

                                <Button
                                    href={'#inicio'}
                                    variant={'text'} sx={{px: masSM ? 4 : 0}}>
                                    Acerca
                                </Button>

                                <Button
                                    href={'#servicios'}
                                    variant={'text'} sx={{px: masSM ? 4 : 0}}>
                                    Servicios
                                </Button>

                                <Button
                                    href={'#portafolio'}
                                    variant={'text'} sx={{px: masSM ? 4 : 0}}>
                                    Portafolio
                                </Button>

                                <Button
                                    href={'#contactos'}
                                    variant={'text'} sx={{px: masSM ? 4 : 0}}>
                                    Contactos
                                </Button>

                                <Grid item container lg={2} sm={4} xs={12} sx={{justifyContent: "center"}}>

                                    <Button
                                        onClick={() => abrir()}
                                        variant={'text'} sx={{px: masSM ? 4 : 0}}>
                                        Administración
                                    </Button>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={8} sm={6} xs={12}
                      sx={{justifyContent: sCell ? 'center' : 'flex-start', marginTop: sCell ? 2 : 0}}>
                    <Typography sx={{color: '#fff', fontWeight: 400, fontSize: 14, textAlign: 'center'}}>2023 Morris,
                        Todos los derechos
                        reservados</Typography>
                </Grid>

                <Grid item container lg={4} sm={6} xs={12}
                      sx={{justifyContent: sCell ? 'center' : "flex-end", marginTop: sCell ? 2 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent={sCell ? 'center' : "flex-end"}
                        alignItems="flex-start"
                    >

                        {REDES.map((it, index) => {
                            return (
                                <Grid item key={index} container lg={1} sm={1} xs={1} sx={{justifyContent: "center"}}>
                                    <ButtonBase
                                        onClick={() => irUrl(it.url)}
                                        sx={{p: 1}}>
                                        <img src={it.img} width={it.size} height={'auto'}/>
                                    </ButtonBase>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}

export default Footer;