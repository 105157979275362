/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import {Add} from "iconsax-react";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import Dialogo_Proyecto from "../Dialogos/Dialogo_Proyecto";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Aplicantes from "../Tarjetas/Tarjeta_Aplicantes";
import {ACENTO} from "../../Constantes";
import useRecuperarColleccionListeningProg from "../../Servicios/BD/useRecuperarColleccionListeningProg";
import {useState} from "react";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Tarjeta_Proyectos from "../Tarjetas/Tarjeta_Proyectos";

const Catalogo = () => {
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Proyecto})
    const [wheres, setWheres] = useState([])
    const {data, cargarMas} = useRecuperarColleccionListeningProg({
        col: 'proyectos',
        wheres: wheres,
        orden: 'nombre',
        direccion: 'desc'
    })


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{p: 3}}
        >

            <Dialogo/>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 28, fontWeight: 700}}>Portafolio</Typography>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                <Button
                    onClick={() => abrir()}
                    startIcon={<Add/>}>
                    Add Proyecto
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Proyectos} data={data} spacing={2} click={abrir}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => cargarMas()}
                    variant={'outlined'}
                    sx={{color: ACENTO}}
                >
                    Cargar Mas
                </Button>
            </Grid>


        </Grid>
    )

}
export default Catalogo