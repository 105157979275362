/*******************************************************
 * Nombre: ComponentesBase Formulario
 * Descripcion : Contiene el template de todos los
 *               componentes que van en un formulario,
 * estos se conectan por medio de un FormularioBase
 *
 * Libreirias: Mui.com, @mui/system, @mui/lab, iconsax-react,
 *             react-number-format, react-datepicker
 *
 * Autor: Luis Rosero
 * Tiempo: 10 hrs
 *******************************************************/
import {createContext, createRef, forwardRef, useContext, useEffect, useRef, useState} from "react";
import {
    Button,
    ButtonBase,
    Checkbox,
    CircularProgress,
    Dialog,
    Divider,
    FormControlLabel,
    Grid, IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Typography, useMediaQuery
} from "@mui/material";
import {Calendar, Component, Document, DocumentCloud, DollarCircle, Gallery, GalleryAdd, Trash} from "iconsax-react";
import NumberFormat from "react-number-format";
import imageCompression from "browser-image-compression";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import {LoadingButton} from "@mui/lab";
import {theme} from "../../Tema";

const formContext = createContext()

const PRIMARIO = "#FFFFFF10"
const COLORACENTO = "#FFFFFF10"

export const FormBase = (props) => {
    const {
        entidad,
        cambio,
        click,
        setDato,
        children,
        cargando,
    } = props


    const {Provider} = formContext;

    const valueProvider = {
        entidad,
        cambio,
        click,
        setDato,
        cargando
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Provider value={valueProvider}>

                {children}
            </Provider>

        </Grid>

    )
}

export const InputIcon = ({
                              Icono = Component,
                              label,
                              dato,
                              lineas = 1,
                              type = "text",
                              editable = true,
                              size = "small"
                          }) => {
    const cData = useContext(formContext)

    const getAltura = (lineas) => {

        switch (lineas) {
            case 2:
                return -lineas * 14
            case 3:
                return -lineas * 16
            case 4:
                return -lineas * 18
            case 5:
                return -lineas * 19.5
            case 6:
                return -lineas * 20
            case 7:
                return -lineas * 20
            case 8:
                return -lineas * 20.5
            case 9:
                return -lineas * 21
            case 10:
                return -lineas * 22.4
        }

    }

    return (
        <TextField label={label}
                   name={dato}
                   value={cData.entidad[dato] || ''}
                   multiline={lineas > 1} rows={lineas}
                   onChange={cData.cambio}
                   disabled={!editable}
                   type={type}
                   size={size}
                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <Icono color={COLORACENTO} variant={"Bold"}
                                      style={{marginTop: lineas > 1 ? getAltura(lineas) : 0}}/>
                           </InputAdornment>
                       ),
                       disableUnderline: true

                   }}
        />
    )
}

export const Input = ({

                          label,
                          dato,
                          lineas = 1,
                          type = "text",
                          editable = true,
                          size = "small",
                          error = false
                      }) => {
    const cData = useContext(formContext)


    return (
        <TextField label={label}
                   name={dato}
                   value={cData.entidad[dato] || ''}
                   multiline={lineas > 1} rows={lineas}
                   onChange={cData.cambio}
                   disabled={!editable}
                   type={type}
                   size={size}
                   error={error}
                   variant={error ? 'outlined' : 'filled'}
                   InputProps={{
                       disableUnderline: true,
                       style: {
                           paddingRight: 0, paddingLeft: 0
                       }

                   }}
                   hiddenLabel={true}

        />
    )
}

export const BotonGuardar = ({children, dato = "submit"}) => {
    const cData = useContext(formContext)

    return (
        <Button variant={"contained"} name={dato} color={"primary"} sx={{color: "#3d3d3d"}}
                onClick={(e) => cData.click(e.target.name)}>
            {children}
        </Button>
    )
}

export const InputCheck = ({label, dato, editable = true}) => {
    const cData = useContext(formContext)
    const [valor, setValor] = useState(false)

    const cambio = (date) => {
        cData.setDato(dato, date.target.checked)
        setValor(!valor)
    }

    useEffect(() => {

        if (cData.entidad && cData.entidad[dato]) {
            let val = cData.entidad[dato] ? cData.entidad[dato] : false
            setValor(val)
        }


    }, [cData.entidad && cData.entidad[dato]])

    return (
        <FormControlLabel
            control={<Checkbox
                color={"secondary"}
                sx={{
                    color: COLORACENTO,
                    '&.Mui-checked': {
                        color: COLORACENTO,
                    },
                }}
                value={valor}
                checked={valor}
                onChange={cambio}
                disabled={!editable}/>}
            componentsProps={{typography: {fontSize: 16, color: COLORACENTO, fontWeight: 600}}}
            label={label}/>
    )
}

export const InputIconSelect = ({Icono = Component, label, dato, editable = true, opciones = [], size = "small"}) => {
    const cData = useContext(formContext)
    return (
        <TextField label={label} name={dato} value={cData.entidad[dato] || ''}
                   select
                   onChange={cData.cambio}
                   disabled={!editable}
                   size={size}

                   InputProps={{
                       startAdornment: (
                           <InputAdornment position="start">
                               <Icono color={COLORACENTO} variant={"Bold"}/>
                           </InputAdornment>
                       ),
                       disableUnderline: true

                   }}
        >
            {opciones.map((item) => {
                return (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                )
            })}

        </TextField>
    )
}

export const InputSelect = ({Icono = Component, label, dato, editable = true, opciones = [], size = "small"}) => {
    const cData = useContext(formContext)
    return (
        <TextField label={label} name={dato} value={cData.entidad[dato] || ''}
                   select
                   onChange={cData.cambio}
                   disabled={!editable}
                   size={size}

                   InputProps={{
                       disableUnderline: true

                   }}
        >
            {opciones.map((item) => {
                return (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                )
            })}

        </TextField>
    )
}

const NumberFormatCustom = forwardRef(({onChange, ...other}, ref) => (


    <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
            onChange({
                target: {
                    name: other.name,
                    value: values.value
                }
            });
        }}
        thousandSeparator
    />


));

export const InputIconMoneda = ({Icono = DollarCircle, label, dato, editable = true, size = "small"}) => {
    const cData = useContext(formContext)
    const [value, setValue] = useState(0)

    const handleChange = event => {
        setValue(parseInt(event.target.value));
        cData.cambio(event);
    };


    useEffect(() => {
        if (cData.entidad[dato]) {
            setValue(cData.entidad[dato])
        }

    }, [cData.entidad[dato]])
    return (
        <TextField
            disabled={!editable}
            label={label}
            value={value}
            onChange={handleChange}
            name={dato}
            size={size}
            id="formatted-numberformat-input"
            InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                    <InputAdornment position="start">
                        <Icono color={editable ? PRIMARIO : "#00000060"} variant={"Bold"}/>
                    </InputAdornment>
                ),
                disableUnderline: true

            }}
        />


    )
}

export const ImagenCustom = ({
                                 dato,
                                 size = "100%",
                                 funcion,
                                 carpeta = "default",
                                 Icono = Gallery
                                 , colorIcono = "#3d3d3d"
                             }) => {
    const cData = useContext(formContext)

    const hiddenFileInput = createRef(null);
    const [cargando, setCargando] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState("");


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)

    }

    const clickBoton = (e) => {
        e.preventDefault();

        hiddenFileInput.current.click();
    };

    const onChange = (e) => {
        if (!cargando) {

            let files;
            const validImageTypes = ["image/jpeg", "image/png",];
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }

            if (files[0] !== undefined) {
                const fileType = files[0]["type"];
                if (validImageTypes.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        abrir()
                    };
                    reader.readAsDataURL(files[0]);
                } else {
                    alert("formato de archivo no soportado");

                }
            }
        } else {
            alert("Hay una imagen procesandoce ahora mismo");

        }
    };

    async function comprimir(url) {

        const imageFile = url;

        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);


            await subir(compressedFile); // write your own logic
        } catch (error) {
            console.log(error);
        }

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCargando(true);
            cerrar()

            cropper.getCroppedCanvas().toBlob(function (blob) {
                comprimir(blob);
                subir(blob)
            });

        }
    };


    const subir = (crop) => {
        setCargando(true)
        funcion(crop, carpeta).then((docs) => {
            if (docs.res) {
                cData.setDato(dato, docs.data)
            }
            setCargando(false)
        })

    };


    return (
        <>
            <input
                type="file"
                onChange={onChange}
                ref={hiddenFileInput}
                style={{display: "none"}}/>
            <ButtonBase sx={{width: size, borderRadius: "10%",}} onClick={(e) => clickBoton(e)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        {cData && cData.entidad[dato] ?
                            <img src={cData && cData.entidad[dato]} width={"100%"}
                                 style={{borderRadius: 8, marginBottom: -8}}/> :
                            <Icono size={"100%"} variant={"Bulk"} color={colorIcono}/>}
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: "-70%"}}>
                        {cargando &&
                            <CircularProgress/>
                        }
                    </Grid>

                </Grid>


            </ButtonBase>

            <Dialog open={open} fullWidth maxWidth={"xs"} onClose={cerrar}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{padding: 2}}
                >
                    <Grid item container sx={{justifyContent: "center"}}>


                        <Cropper
                            style={{
                                height: 300,
                                width: 300,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                            src={image}
                            viewMode={2}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={true}
                            responsive={true}
                            autoCropArea={0.5}
                            modal={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />

                    </Grid>

                    <Grid item container sx={{justifyContent: "center"}}>


                        <Button variant={"contained"} color={"secondary"} size={"small"}
                                onClick={() => getCropData()}
                        >cortar y Subir</Button>
                    </Grid>

                </Grid>

            </Dialog>
        </>

    )
}

export const ImagenDimencion = ({
                                    dato,
                                    size = "100%",
                                    funcion,
                                    carpeta = "default",
                                    Icono = Gallery
                                    , colorIcono = "#3d3d3d",
                                    x = 1,
                                    y = 1
                                }) => {
    const cData = useContext(formContext)

    const hiddenFileInput = createRef(null);
    const [cargando, setCargando] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState("");


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)

    }

    const clickBoton = (e) => {
        e.preventDefault();
        hiddenFileInput.current.click();
    };

    const onChange = (e) => {
        if (!cargando) {

            let files;
            const validImageTypes = ["image/jpeg", "image/png",];
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }

            if (files[0] !== undefined) {
                const fileType = files[0]["type"];
                if (validImageTypes.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        abrir()
                    };
                    reader.readAsDataURL(files[0]);
                } else {
                    alert("formato de archivo no soportado");

                }
            }
        } else {
            alert("Hay una imagen procesandoce ahora mismo");

        }
    };

    async function comprimir(url) {

        const imageFile = url;

        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);


            await subir(compressedFile); // write your own logic
        } catch (error) {
            console.log(error);
        }

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCargando(true);
            cerrar()

            cropper.getCroppedCanvas().toBlob(function (blob) {
                comprimir(blob);
                subir(blob)
            });

        }
    };


    const subir = (crop) => {
        setCargando(true)
        funcion(crop, carpeta).then((docs) => {
            if (docs.res) {
                cData.setDato(dato, docs.data)
            }
            setCargando(false)
        })

    };


    return (
        <>
            <input
                type="file"
                onChange={onChange}
                ref={hiddenFileInput}
                style={{display: "none"}}/>
            <ButtonBase sx={{width: size, borderRadius: "10%",}} onClick={(e) => clickBoton(e)}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        {cData && cData.entidad[dato] ?
                            <img src={cData && cData.entidad[dato]} width={"100%"}
                                 style={{borderRadius: 8, marginBottom: -8}}/> :
                            <Icono size={"100%"} variant={"Bulk"} color={colorIcono}/>}
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: "-70%"}}>
                        {cargando &&
                            <CircularProgress/>
                        }
                    </Grid>

                </Grid>


            </ButtonBase>

            <Dialog open={open} fullWidth maxWidth={"xs"} onClose={cerrar}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{padding: 2}}
                >
                    <Grid item container sx={{justifyContent: "center"}}>


                        <Cropper
                            style={{
                                height: 300,
                                width: 300,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                            src={image}
                            viewMode={2}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={true}
                            responsive={true}
                            autoCropArea={0.5}
                            modal={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            aspectRatio={x / y}

                        />

                    </Grid>

                    <Grid item container sx={{justifyContent: "center"}}>


                        <Button variant={"contained"} color={"secondary"} size={"small"}
                                onClick={() => getCropData()}
                        >Cortar y Subir</Button>
                    </Grid>

                </Grid>

            </Dialog>
        </>

    )
}

export const InputListaSelect = ({
                                     Icono = Component, label, dato, editable = true,
                                     opciones = [], size = "small"
                                 }) => {
    const cData = useContext(formContext)
    const [valores, setValores] = useState([])

    const adicionar = (e) => {

        setValores((arr) => arr.concat(e.replaceAll(" ", "_")))

    }

    const borrar = (e) => {

        let arr = []
        for (let i = 0; i < valores.length; i++) {
            if (e !== valores[i]) {
                arr.push(valores[i])
            }
        }
        setValores(arr)
    }


    useEffect(() => {
        cData.setDato(dato, valores)
    }, [valores])

    useEffect(() => {

        if (cData.entidad[dato] && cData.entidad[dato] !== valores) {
            setValores(cData.entidad[dato])
        }

    }, [cData.entidad[dato]])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Grid item lg={12} sm={10} xs={10}>
                <TextField label={label} name={dato}
                           select
                           size={size}
                           onChange={(e) => adicionar(e.target.value)}
                           disabled={!editable}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <Icono color={editable ? PRIMARIO : "#00000060"} variant={"Bold"}/>
                                   </InputAdornment>
                               ),

                           }}
                >
                    {opciones.map((item) => {
                        return (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        )
                    })}

                </TextField>
            </Grid>


            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {valores.map((item, index) => {
                        return (
                            <Grid item lg={12} sm={12} xs={12} key={index}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >

                                    <Grid item lg={10} sm={12} xs={12}>
                                        <Typography sx={{color: "#000"}}>{"• " + item}</Typography>
                                    </Grid>

                                    <Grid item container lg={2} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                                        <ButtonBase sx={{p: 0.5}} onClick={() => borrar(item)}>
                                            <Trash variant={"Bold"}/>
                                        </ButtonBase>
                                    </Grid>

                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                        )
                    })}

                </Grid>

            </Grid>


        </Grid>

    )
}


export const InputLista = ({
                               Icono = Component, label, dato, editable = true,
                               opciones = [], size = "small"
                           }) => {
    const cData = useContext(formContext)
    const [valores, setValores] = useState([])
    const [valorIngreso, setValorIngreso] = useState('')

    const adicionar = (e) => {

        setValores((arr) => arr.concat(valorIngreso))
        setValorIngreso('')

    }

    const borrar = (e) => {

        let arr = []
        for (let i = 0; i < valores.length; i++) {
            if (e !== valores[i]) {
                arr.push(valores[i])
            }
        }
        setValores(arr)
    }


    useEffect(() => {
        cData.setDato(dato, valores)
    }, [valores])

    useEffect(() => {

        if (cData.entidad[dato] && cData.entidad[dato] !== valores) {
            setValores(cData.entidad[dato])
        }

    }, [cData.entidad[dato]])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >

            <Grid item lg={12} sm={10} xs={10}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >

                    <Grid item lg={8} sm={10} xs={9}>
                        <TextField label={label} name={dato}
                                   size={size}
                                   value={valorIngreso}
                                   onChange={(e) => setValorIngreso(e.target.value)}
                                   disabled={!editable}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">
                                               <Icono color={editable ? PRIMARIO : "#00000060"} variant={"Bold"}/>
                                           </InputAdornment>
                                       ),
                                       disableUnderline: true


                                   }}
                        />
                    </Grid>

                    <Grid item lg={4} sm={2} xs={3}>
                        <Button
                            onClick={() => adicionar()}
                            sx={{backgroundColor: PRIMARIO, borderRadius: 2}}>Agregar</Button>
                    </Grid>

                </Grid>


            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{px: 2}}>
                <Typography sx={{color: PRIMARIO, fontWeight: 600}}>Lista:</Typography>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >

                    {valores.map((item, index) => {
                        return (
                            <Grid item lg={12} sm={12} xs={12} key={index}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >

                                    <Grid item lg={10} sm={10} xs={10}>
                                        <Typography sx={{color: "#000"}}>{"• " + item}</Typography>
                                    </Grid>

                                    <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "flex-end"}}>
                                        <ButtonBase sx={{p: 0.5}} onClick={() => borrar(item)}>
                                            <Trash variant={"Bold"}/>
                                        </ButtonBase>
                                    </Grid>

                                </Grid>

                                <Grid item lg={12} sm={12} xs={12}>
                                    <Divider/>
                                </Grid>
                            </Grid>
                        )
                    })}

                </Grid>

            </Grid>


        </Grid>

    )
}


export const DividerTexto = ({texto, lg = 12, sm = 12, xs = 12, color = '#6A6A6A'}) => {

    return (
        <Grid item lg={12} sm={12} xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{px: 1}}
            >

                <Grid item lg={lg} sm={sm} xs={xs}>
                    <Typography sx={{fontSize: 14, color: color}}>{texto}</Typography>
                </Grid>

                <Grid item lg={12 - lg} sm={12 - sm} xs={12 - xs}>
                    <Divider sx={{width: "100%"}} color={color}/>
                </Grid>

            </Grid>
        </Grid>

    )
}

export const InputCredenciales = ({
                                      dato,
                                      credenciales,
                                      lg = 12,
                                      sm = 12,
                                      xs = 12,
                                      color = "secondary",
                                      editable = true
                                  }) => {
    const [arrCredenciales, setArrCredenciales] = useState([])
    const cData = useContext(formContext)

    const cambio = (valor, item) => {

        let arr = arrCredenciales
        if (valor) {
            arr.push(item)
            setArrCredenciales(arr)
        } else {
            let index = arr.indexOf(item)
            arr.splice(index, 1)
            setArrCredenciales(arr)
        }
        cData.setDato(dato, arr)
    }

    useEffect(() => {


        if (cData.entidad[dato]) {
            setArrCredenciales(cData.entidad[dato])
        }

    }, [cData])
    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            {credenciales.map((item, index) => {
                return (
                    <Grid item lg={lg} sm={sm} xs={xs} key={index}>
                        <FormControlLabel
                            control={<Checkbox
                                color={color}
                                sx={{
                                    color: COLORACENTO,
                                    '&.Mui-checked': {
                                        color: COLORACENTO,
                                    },
                                }}
                                value={item}
                                checked={arrCredenciales.find(e => e === item) !== undefined}
                                onChange={(e) => cambio(e.target.checked, item)}
                                disabled={!editable}/>}
                            componentsProps={{typography: {fontSize: 16, color: COLORACENTO, fontWeight: 600}}}
                            label={item}/>
                    </Grid>
                )
            })}

        </Grid>

    )
}

export const InputIconFecha = ({Icono = Calendar, label, dato, editable = true}) => {

    const cData = useContext(formContext)
    const [value, setValue] = useState('')


    const ExampleTextField = forwardRef(({value, onClick, label,}, ref) => (


        <TextField
            label={label}
            onClick={() => editable ? onClick() : alert("Fecha no editable")}
            value={value}
            disabled={!editable}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Calendar variant={"Bold"} color={COLORACENTO}/>
                    </InputAdornment>

                ),
                disableUnderline: true
            }}
        />


    ));


    const cambio = (date) => {
        setValue(date)
        cData.setDato(dato, date.getTime())

    }

    const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const locale = {
        localize: {
            day: n => days[n],
            month: n => months[n]
        },
        formatLong: {
            date: () => 'dd/mm/yyyy'
        }
    }


    useEffect(() => {


        if (cData.entidad && cData.entidad[dato]) {
            setValue(new Date(cData.entidad[dato]))
        } else {
            setValue('')
        }

    }, [dato, cData])
    return (
        <DatePicker
            locale={locale}
            dateFormat="dd MMMM yyyy"
            selected={value}
            onChange={(date) => cambio(date)}
            customInput={<ExampleTextField Icono={Icono} label={label}/>}
            withPortal
            showMonthDropdown
            showYearDropdown
            yearDropdownItemNumber={70}
            scrollableYearDropdown

        />
    )
}

export const InputIconHora = ({Icono = Calendar, label, dato, editable = true}) => {

    const cData = useContext(formContext)
    const [value, setValue] = useState('')


    const ExampleTextField = forwardRef(({value, onClick, label,}, ref) => (


        <TextField
            label={label}
            onClick={() => editable ? onClick() : alert("Fecha no editable")}
            value={value}
            disabled={!editable}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Calendar variant={"Bold"} color={COLORACENTO}/>
                    </InputAdornment>

                ),
                disableUnderline: true
            }}
        />


    ));


    const cambio = (date) => {
        setValue(date)
        cData.setDato(dato, date.getTime())

    }

    const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const locale = {
        localize: {
            day: n => days[n],
            month: n => months[n]
        },
        formatLong: {
            date: () => 'hh/mm'
        }
    }


    useEffect(() => {


        if (cData.entidad && cData.entidad[dato]) {
            setValue(new Date(cData.entidad[dato]))
        } else {
            setValue('')
        }

    }, [dato, cData])
    return (
        <DatePicker
            /// locale={locale}
            selected={value}
            onChange={(date) => cambio(date)}
            customInput={<ExampleTextField Icono={Icono} label={label}/>}
            withPortal
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"

        />
    )
}

export const Imagenes = ({
                             lg = 12,
                             sm = 12,
                             sx = 12,
                             dato,
                             funcion,
                             carpeta = "default",
                             Icono = GalleryAdd,
                             colorIcono = "#3d3d3d",
                             editable = true
                         }) => {
    const cData = useContext(formContext)
    const refe = useRef()
    const hiddenFileInput = createRef(null);
    const [cargando, setCargando] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState("");
    const [valores, setValores] = useState([])


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)

    }

    const borrar = (url) => {
        let arr = valores;
        let nuevo = []

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] !== url) {
                nuevo.push(arr[i])
            }
        }
        setValores(nuevo)
        cData.setDato(dato, nuevo)

    }

    const clickBoton = (e) => {
        e.preventDefault();

        hiddenFileInput.current.click();
    };

    const onChange = (e) => {
        if (!cargando) {

            let files;
            const validImageTypes = ["image/jpeg", "image/png",];
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }

            if (files[0] !== undefined) {
                const fileType = files[0]["type"];
                if (validImageTypes.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        abrir()
                    };
                    reader.readAsDataURL(files[0]);
                } else {
                    alert("formato de archivo no soportado");

                }
            }
        } else {
            alert("Hay una imagen procesandoce ahora mismo");

        }
    };

    async function comprimir(url) {

        const imageFile = url;

        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);


            await subir(compressedFile); // write your own logic
        } catch (error) {
            console.log(error);
        }

    }

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCargando(true);
            cerrar()
            cropper.getCroppedCanvas().toBlob(function (blob) {
                comprimir(blob);
            });
        }
    };

    const subir = (crop) => {
        setCargando(true)
        funcion(crop, carpeta).then((docs) => {
            if (docs.res) {
                let arr = valores;
                setValores((arr) => arr.concat(docs.data))
                arr.push(docs.data)
                cData.setDato(dato, arr)
            }
            setCargando(false)
        })

    };


    useEffect(() => {
        if (cData.entidad[dato]) {
            setValores(cData.entidad[dato])
        } else {
            setValores([])
        }

    }, [cData])
    return (
        <>
            <input
                type="file"
                onChange={onChange}
                ref={hiddenFileInput}
                style={{display: "none"}}/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >


                {valores.map((url) => {
                    return (
                        <Grid item container lg={lg} sm={sm} xs={sx} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "flex-end", marginBottom: -10, zIndex: 10}}>
                                    <IconButton sx={{backgroundColor: PRIMARIO}}
                                                onClick={() => editable ? borrar(url) : alert("No se puede editar")}
                                    >
                                        <Trash color={"#fff"} variant={"Bold"}/>
                                    </IconButton>
                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 4}}>
                                    <Zoom>
                                        <img src={url}
                                             style={{
                                                 width: "100%",
                                                 borderRadius: 10,
                                                 height: "auto",
                                                 objectFit: "cover"
                                             }}/>
                                    </Zoom>
                                </Grid>

                            </Grid>


                        </Grid>
                    )
                })}


                <Grid item container lg={lg} sm={sm} xs={sx} sx={{justifyContent: "center"}}>
                    <IconButton sx={{width: "100%", borderRadius: "10%"}}
                                onClick={(e) => editable ? clickBoton(e) : alert("No se puede editar")}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >

                            <Grid item lg={12} sm={12} xs={12}>
                                <Icono size={"100%"} variant={"Bulk"} color={colorIcono}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: "-70%"}}>
                                {cargando &&
                                    <CircularProgress/>
                                }
                            </Grid>

                        </Grid>


                    </IconButton>
                </Grid>

            </Grid>


            <Dialog open={open} fullWidth maxWidth={"xs"} onClose={cerrar}>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{padding: 2}}
                >
                    <Grid item container sx={{justifyContent: "center"}}>


                        <Cropper
                            style={{
                                height: 300,
                                width: 300,
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                            src={image}
                            viewMode={2}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={true}
                            responsive={true}
                            autoCropArea={0.5}
                            modal={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />

                    </Grid>

                    <Grid item container sx={{justifyContent: "center"}}>


                        <Button variant={"contained"} color={"secondary"} size={"small"}
                                onClick={() => getCropData()}
                        >cortar y Subir</Button>
                    </Grid>

                </Grid>

            </Dialog>
        </>

    )
}


export const InputArchivo = ({
                                 dato,
                                 funcion,
                                 carpeta = "default",

                             }) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const cData = useContext(formContext)

    const hiddenFileInput = createRef(null);
    const [cargando, setCargando] = useState(false);
    const [ext, setExt] = useState('')
    const [nombre, setNombre] = useState('')
    const [url, setUrl] = useState('')


    const clickBoton = (e) => {
        e.preventDefault();
        hiddenFileInput.current.click();
    };

    const onChange = (e) => {

        if (!ext || !nombre) {
            alert('Antes de subir el archivo debe escribir su nombre y tipo')
            return
        }

        if (!cargando) {

            let files;
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }


            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {

                subir(files[0])
            };


        } else {
            alert("Hay una imagen procesandoce ahora mismo");

        }
    };


    const subir = (crop) => {
        setCargando(true)
        setUrl('')
        funcion(crop, carpeta, nombre, ext).then((docs) => {
            if (docs.res) {
                cData.setDato(dato, docs.data)
            }
            setCargando(false)
        })

    };


    useEffect(() => {

        if (cData.entidad[dato]) {
            setUrl(cData.entidad[dato])
        }

    }, [cData])
    return (
        <>
            <input
                type="file"
                onChange={onChange}
                ref={hiddenFileInput}
                style={{display: "none"}}/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item lg={8} sm={8} xs={12}>
                    <TextField
                        label={"Nombre de Archivo"}
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Document variant={"Bold"} color={COLORACENTO}/>
                                </InputAdornment>

                            ),
                            disableUnderline: true
                        }}
                    />
                </Grid>

                <Grid item lg={4} sm={4} xs={6}>
                    <TextField
                        label={"Tipo de Archivo"}
                        value={ext}
                        onChange={(e) => setExt(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DocumentCloud variant={"Bold"} color={COLORACENTO}/>
                                </InputAdornment>

                            ),
                            disableUnderline: true
                        }}
                    />
                </Grid>

                <Grid container item lg={8} sm={8} xs={6} sx={{justifyContent: sCell ? 'center' : 'flex-start'}}>
                    <LoadingButton
                        loading={cargando}
                        variant={'outlined'}
                        onClick={(e) => clickBoton(e)}
                        sx={{borderRadius: 2, color: PRIMARIO, fontWeight: 500, px: 4}}>
                        {sCell ? 'Cargar' : 'Cargar Archivo'}
                    </LoadingButton>
                </Grid>

                <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: sCell ? 'center' : "flex-end"}}>
                    {url &&
                        <ButtonBase onClick={() => window.open(url)}>
                            <Typography sx={{textDecoration: 'underline'}}>
                                Ver Archivo
                            </Typography>

                        </ButtonBase>
                    }

                </Grid>


            </Grid>


        </>

    )
}

FormBase.InputIconSelect = InputIconSelect;
FormBase.InputSelect = InputSelect;
FormBase.InputListaSelect = InputListaSelect;
FormBase.InputLista = InputLista;
FormBase.InputIconMoneda = InputIconMoneda;
FormBase.Input = Input;
FormBase.InputIcon = InputIcon;
FormBase.InputCheck = InputCheck;
FormBase.InputArchivo = InputArchivo;

FormBase.BotonGuardar = BotonGuardar;

FormBase.ImagenCustom = ImagenCustom;
FormBase.ImagenDimencion = ImagenDimencion;
FormBase.Imagenes = Imagenes;

FormBase.DividerTexto = DividerTexto;
FormBase.InputCredenciales = InputCredenciales;
FormBase.InputIconFecha = InputIconFecha;
FormBase.InputIconHora = InputIconHora;











