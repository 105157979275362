/**************************************************
 * Nombre:       Formulario_Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Lock, Sms} from "iconsax-react";


const Formulario_Login = ({...props}) => {

    return (
        <FormBase {...props}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon dato={'correo'} label={'Correo de Usuario'} Icono={Sms}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <FormBase.InputIcon dato={'pass'} label={'Contraseña'} Icono={Lock} type={'password'}/>
                </Grid>


            </Grid>
        </FormBase>
    )
}

export default Formulario_Login;