/**************************************************
 * Nombre:       Tarjeta_Potenciales
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {ButtonBase, Grid, Tooltip} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Award, Flag, Mobile, Sms, Trash, User} from "iconsax-react";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";

const Tarjeta_Aplicantes = ({valores, click}) => {

    const borrar = () => {
        borrarDoc('aplicantes', valores.id).then((dox) => {
            if (dox.res) {
                alert('Aplicante borrado con exito')
            }
        })
    }

    return (<CardBase valores={valores}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{p: 1}}
            >

                <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <CardBase.FechaComponente dato1={'fecha'}>
                        <CardBase.IconTexto dato1={'nombre'} Icono={User}/>
                    </CardBase.FechaComponente>
                </Grid>

                <Grid item container lg={3} sm={3} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <CardBase.IconTexto dato1={'correo'} Icono={Sms}/>
                </Grid>

                <Grid item container lg={4} sm={4} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <CardBase.IconTexto dato1={'cargo'} Icono={Award}/>
                </Grid>

                <Grid item container lg={1} sm={1} xs={12} sx={{justifyContent: 'flex-end'}}>
                    <Tooltip title={'Borrar Aplicante'}>
                        <ButtonBase
                            onClick={() => borrar()}
                            sx={{borderRadius: 2, p: 0.4}}>
                            <Trash variant={'Bold'} size={'1.8rem'}/>
                        </ButtonBase>
                    </Tooltip>
                </Grid>


            </Grid>
        </CardBase>
    )

}
export default Tarjeta_Aplicantes